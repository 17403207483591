.room-list-loop .room-box .room-img-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 310px;
}
  
@media only screen and (min-width: 768px) and (max-width: 991px) {
.room-list-loop .room-box .room-img-wrap {
    min-height: 350px;
}
}

@media (max-width: 575px) {
.room-list-loop .room-box .room-img-wrap {
    min-height: 250px;
}
}

.room-list-loop .room-box .room-img-wrap .room-img {
    height: 100%;
    width: 100%;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    background-size: cover;
    background-position: center;
}

.room-list-loop .room-box:hover .room-img {
    -webkit-transform: scale(1.1);
        transform: scale(1.1);
}

.room-list-loop .room-box .room-desc {
    background-color: #fff;
    font-size: 14px;
    padding: 50px 20px 50px 40px;
    position: relative;
    height: 100%;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-list-loop .room-box .room-desc {
        padding: 50px 20px 50px 25px;
    }
}

@media (max-width: 575px) {
    .room-list-loop .room-box .room-desc {
        padding: 50px 30px;
    }
}

@media (max-width: 399px) {
    .room-list-loop .room-box .room-desc {
        padding: 50px 25px;
    }
}

.room-list-loop .room-box .room-desc .icons {
    background-color: #fff;
    padding: 15px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
            box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    display: inline-block;
    margin-bottom: 25px;
}

.room-list-loop .room-box .room-desc .icons li {
    display: inline-block;
    margin: 0px 10px;
    color: #222;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-list-loop .room-box .room-desc .icons li {
        margin: 0 8px;
    }
}

@media (max-width: 575px) {
    .room-list-loop .room-box .room-desc .icons li {
        margin: 0 5px;
    }
}

.room-list-loop .room-box .room-desc .icons .icon-amenties:Hover{
    color: brown;
    -webkit-transform: scale(2);
            transform: scale(2);
}


.room-list-loop .room-box .room-desc h4 {
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 15px;
}

.room-list-loop .room-box .room-desc a.book-btn {
    font-size: 16px;
    font-weight: 700;
    color: #bdbdbd;
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
}

.room-list-loop .room-box .room-desc a.detalii-btn {
    font-size: 16px;
    font-weight: 700;
    color: #bdbdbd;
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
}

.room-list-loop .room-box .room-desc a.book-btn:hover {
    color: #bead8e;
}

.room-list-loop .room-box .room-desc a.book-btn::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 0;
    height: 2px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 60px;
    background-color: #efefef;
}

.room-list-loop .room-box .room-desc .price {
    font-size: 40px;
    font-weight: 700;
    color: #bead8e;
    line-height: 1;
    display: block;
}

@media (max-width: 575px) {
    .room-list-loop .room-box .room-desc .price {
        margin-top: 30px;
    }
}

.room-list-loop .room-box .room-desc .price span {
    font-size: 16px;
}


.room-list-loop .room-box .room-desc .price-single {
    font-size: 16px;
    font-weight: 700;
    color: #bead8e;
}
.room-list-loop .room-box .room-desc .price-single-regim {
    font-size: 12px;
    font-weight: 700;
    color: #bead8e;
    }

@media (max-width: 575px) {
    .room-list-loop .room-box .room-desc span.price-single{
        font-size: 14px;
    }
    .room-list-loop .room-box .room-desc .price-single-regim {
        font-size: 12px;
    }
}

