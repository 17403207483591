.preloader {
    background-color: #bead8e;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  
  .preloader .cssload-container {
    display: block;
    width: 97px;
  }
  
  .preloader .cssload-loading i {
    width: 19px;
    height: 19px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
  }
  
  .preloader .cssload-loading i:first-child {
    opacity: 0;
    -webkit-animation: cssload-loading-ani2 0.58s linear infinite;
            animation: cssload-loading-ani2 0.58s linear infinite;
    -webkit-transform: translate(-19px);
            transform: translate(-19px);
  }
  
  .preloader .cssload-loading i:nth-child(2),
  .preloader .cssload-loading i:nth-child(3) {
    -webkit-animation: cssload-loading-ani3 0.58s linear infinite;
            animation: cssload-loading-ani3 0.58s linear infinite;
  }
  
  .preloader .cssload-loading i:last-child {
    -webkit-animation: cssload-loading-ani1 0.58s linear infinite;
            animation: cssload-loading-ani1 0.58s linear infinite;
  }
  