.room-details .deatils-box {
    font-family: "Roboto", sans-serif;
  }
  
  .room-details .deatils-box .title-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 40px 50px;
    background-color: #fff;
    margin-right: 40px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .title-wrap {
      padding: 40px 30px;
      margin-right: 30px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .title-wrap {
      padding: 30px 20px;
      margin-right: 20px;
    }
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .title-wrap {
      padding: 30px;
      margin-right: 20px;
      display: block;
    }
  }
  
  .room-details .deatils-box .title-wrap .title .room-cat {
    text-transform: uppercase;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
  }
  
  .room-details .deatils-box .title-wrap .title h2 {
    font-size: 48px;
    letter-spacing: -1px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .title-wrap .title h2 {
      font-size: 42px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .title-wrap .title h2 {
      font-size: 32px;
    }
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .title-wrap .title h2 {
      font-size: 28px;
    }
  }
  
  .room-details .deatils-box .title-wrap .price {
    font-size: 40px;
    color: #bead8e;
    font-weight: 700;
    font-family: "Archivo", sans-serif;
  }
  .room-details .deatils-box .title-wrap .price-single {
    font-size: 20px;
    color: #bead8e;
    font-weight: 700;
    font-family: "Archivo", sans-serif;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .title-wrap .price {
      font-size: 35px;
    }
    .room-details .deatils-box .title-wrap .price-single {
      font-size: 15px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .title-wrap .price {
      font-size: 30px;
    }
    .room-details .deatils-box .title-wrap .price-single {
      font-size: 12px;
    }
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .title-wrap .price {
      font-size: 24px;
      margin-top: 15px;
    }
    .room-details .deatils-box .title-wrap .price-single {
      font-size: 12px;
    }
  }
  
  .room-details .deatils-box .title-wrap .price span {
    font-size: 16px;
  }
  .room-details .deatils-box .title-wrap .price-single-regim {
    font-size: 12px;
  }
  
  .room-details .deatils-box .thumb {
    margin-top: -90px;
    position: relative;
    z-index: -1;
    margin-bottom: 60px;
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .thumb {
      margin-top: -65px;
    }
  }
  
  .room-details .deatils-box h3.subtitle {
    font-size: 30px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #f1f1f1;
  }
  
  .room-details .deatils-box .room-fearures {
    background-color: #fff;
    padding: 50px 50px 30px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .room-fearures {
      padding: 50px 35px 30px;
    }
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .room-fearures {
      padding: 50px 30px 30px;
    }
  }
  
  .room-details .deatils-box .room-fearures .room-fearures-list li {
    width: 33.33%;
    float: left;
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .room-fearures .room-fearures-list li {
      width: 50%;
    }
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .room-fearures .room-fearures-list li {
      width: 100%;
    }
  }
  
  .room-details .deatils-box .room-fearures .room-fearures-list li i {
    color: #bead8e;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  
  .room-details .deatils-box .room-rules {
    background-color: #fff;
    padding: 50px 50px 30px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .room-rules {
      padding: 50px 35px 30px;
    }
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .room-rules {
      padding: 50px 30px 30px;
    }
  }
  
  .room-details .deatils-box .room-rules .room-rules-list li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px;
  }
  
  .room-details .deatils-box .room-rules .room-rules-list li::before {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #bead8e;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    content: '\f00c';
  }
  
  .room-details .deatils-box .cancellation-box {
    background-color: #fff;
    padding: 50px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .cancellation-box {
      padding: 50px 35px;
    }
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .cancellation-box {
      padding: 50px 30px;
    }
  }
  
  .room-details .deatils-box .cancellation-box strong {
    color: #222;
  }
  
  .room-details .deatils-box .room-map {
    width: 100%;
    height: 390px;
    background-color: #fff;
  }
  
  @media (max-width: 575px) {
    .room-details .deatils-box .room-map {
      height: 320px;
    }
  }
  
  .room-details .deatils-box .room-map iframe {
    width: 100%;
    height: 100%;
  }
  
  .room-details .deatils-box .related-room h3.subtitle {
    border-color: #dddddd;
  }
  
  .room-details .room-booking-form {
    background-color: #fff;
    padding: 40px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-details .room-booking-form {
      margin-top: 30px;
    }
  }
  
  @media (max-width: 767px) {
    .room-details .room-booking-form {
      margin-top: 30px;
    }
  }
  
  .room-details .room-booking-form input,
  .room-details .room-booking-form .nice-select,
  .room-details .room-booking-form select {
    background-color: #f8f8f8;
  }
  
  .room-details .room-booking-form .main-btn {
    height: 60px;
    line-height: 60px;
    -webkit-box-shadow: none;
            box-shadow: none;
    width: 100%;
    padding: 0;
  }
  
  .room-details .room-booking-form .title {
    font-weight: 700;
    font-family: "Archivo", sans-serif;
    margin-bottom: 30px;
    padding-left: 60px;
    position: relative;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .room-booking-form .title {
      padding-left: 50px;
    }
  }
  
  @media (max-width: 399px) {
    .room-details .room-booking-form .title {
      padding-left: 50px;
    }
  }
  
  .room-details .room-booking-form .title::before {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 40px;
    height: 2px;
    background-color: #bead8e;
    content: '';
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .room-booking-form .title::before {
      width: 30px;
    }
  }
  
  @media (max-width: 399px) {
    .room-details .room-booking-form .title::before {
      width: 30px;
    }
  }
  
  