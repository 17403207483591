.testimonial-box {
  background-color: #fff;
  text-align: center;
  padding: 50px 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box {
    padding: 50px 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-box {
    padding: 50px 20px;
    font-size: 14px;
  }
}

.testimonial-box .client-img {
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  position: relative;
}

.testimonial-box .client-img img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
}

.testimonial-box .client-img .check {
  position: absolute;
  right: 0;
  top: 0;
  border: 4px solid #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #bead8e;
  text-align: center;
  line-height: 50px;
  color: #fff;
}

.testimonial-box h3 {
  font-size: 30px;
  margin-bottom: 15px;
  margin-top: 35px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-box h3 {
    font-size: 22px;
  }
}

.testimonial-box p {
  height: 100px;
}


.testimonial-box .clinet-post {
  font-weight: bold;
  text-transform: uppercase;
  color: #bead8e;
  margin-bottom: 30px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box .clinet-post {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box .clinet-post {
    font-size: 12px;
  }
}

.testimonial-box:hover {
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
}

.testimonial-slider ul.slick-dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.testimonial-slider ul.slick-dots li {
  display: inline-block;
  cursor: pointer;
}

.testimonial-slider ul.slick-dots li button {
  font-size: 0;
  border: 0;
  background: #e7e7e7;
  width: 16px;
  height: 4px;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.testimonial-slider ul.slick-dots li.slick-active button {
  width: 26px;
  background-color: #bead8e;
}
