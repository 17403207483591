.text-block.with-bg {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.text-block.with-bg::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #fff;
  z-index: -1;
  opacity: 0.94;
}

.text-block.bg-black p,
.text-block.bg-black .section-title h2 {
  color: #fff;
}

.text-block.bg-black .section-title .title-tag {
  color: #bead8e;
}

.text-block.bg-black .main-btn.btn-filled {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.text-block.bg-black .main-btn.btn-filled:hover {
  color: #fff;
}

.text-block.with-pattern {
  position: relative;
  z-index: 1;
}

.text-block.with-pattern .pattern-wrap {
  position: absolute;
  background: #bead8e;
  right: 0;
  height: 100%;
  z-index: -1;
  width: 30%;
  top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-block.with-pattern .pattern-wrap {
    width: 100%;
    height: 320px;
  }
}

@media (max-width: 767px) {
  .text-block.with-pattern .pattern-wrap {
    width: 100%;
    height: 320px;
  }
}

.text-block.with-pattern .pattern-wrap .pattern {
  position: relative;
  width: 100%;
  height: 100%;
}

.text-block.with-pattern .pattern-wrap .pattern::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url(../img/icon/pattern-03.png);
  content: '';
  background-size: cover;
  background-position: right;
}

@media (max-width: 575px) {
  .text-block .block-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-block .mb-small {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .text-block .mb-small {
    margin-bottom: 50px;
  }
}
