@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&family=Roboto:wght@400;500;700&display=swap");

body {
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #777;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.6;
  background-color: #f8f8f8;
}

@media (max-width: 399px) {
  body {
    font-size: 14px;
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

a{
  text-decoration: none;
}

a:focus,
input:focus,
textarea:focus,
button:focus,
.slick-initialized .slick-slide:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Old Standard TT", serif;
  font-weight: 400;
  margin: 0px;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
  color: #222;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  margin: 0px;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a, a:hover {
  color: #bead8e;
}

input,
select,
textarea,
select {
  border: none;
  width: 100%;
  height: 70px;
  background-color: #f8f8f8;
  padding: 0 40px;
}

textarea {
  height: 250px;
  padding-top: 25px;
}

::-webkit-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

:-ms-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

::-ms-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

::placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

.nice-select {
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  position: relative;
  border: none;
  height: 70px;
  padding-left: 50px;
  line-height: 70px;
}

.nice-select .list {
  width: 100%;
  border-radius: 0;
}

.nice-select .list li {
  line-height: 35px;
  color: #777;
}

.nice-select .list li:hover, .nice-select .list li.option:hover {
  background-color: #bead8e;
  color: #fff;
}

.nice-select::after {
  display: none;
}

.nice-select::before {
  content: '\f063';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  left: 30px;
  top: 50%;
  color: #bead8e;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}


.input-group input,
.input-group select,
.input-group textarea,
.input-group select {
  padding-left: 80px;
}

 .input-group label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #222;
  display: block;
}

.input-group .icon {
  position: absolute;
  left: 40px;
  bottom: 35px;
  color: #bead8e;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.input-group.textarea .icon {
  top: 25px;
  bottom: auto;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.input-group .nice-select {
  padding-left: 80px;
}

.input-group .nice-select::before {
  left: 40px;
}

.xinput-group{
  line-height:0%;
  margin-top: 40px;
}

.xinput-group.input-group-two input,
.xinput-group.input-group-two select,
.xinput-group.input-group-two textarea,
.xinput-group.input-group-two select {
  height: 60px;
  padding: 0px 50px 0 50px;
  background-color: #fff;
  color: #777;
}

.xinput-group.input-group-two ::-webkit-input-placeholder {
  opacity: 1;
  color: #777;
}

.xinput-group.input-group-two :-ms-input-placeholder {
  opacity: 1;
  color: #777;
}

.xinput-group.input-group-two ::-ms-input-placeholder {
  opacity: 1;
  color: #777;
}

.xinput-group.input-group-two ::placeholder {
  opacity: 1;
  color: #777;
}

.xinput-group.input-group-two .icon {
  position: relative;
  z-index: 1;
  left: 20px;
  bottom: -38px;
  /* right: 30px; */
  max-width: 30px;
  color:  #bead8e;
}

.xinput-group.input-group-two .nice-select {
  background-color: #fff;
  padding: 0 50px 0 30px;
  height: 60px;
  line-height: 60px;
}

.xinput-group.input-group-two .nice-select::before {
  left: auto;
  right: 30px;
}

/*=======  COMMON CLASS  =======*/
/* @media (min-width: 1600px) {
  .container-custom-one {
    max-width: 1600px;
  }
}

@media (min-width: 1600px) {
  .container-custom-two {
    max-width: 1400px;
  }
}

@media (min-width: 1600px) {
  .container-custom-three {
    max-width: 1420px;
  }
} */

.slick-slide img {
  display: inline-block;
}

.bg-black {
  background-color: #222;
}

.main-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 48px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #777;
  border: 2px solid #bead8e;
}

.main-btn.btn-filled {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}

.checkbox-confidential{
  width: 20px;
  height: 20px;
}

.main-btn.btn-filled:disabled {
  background-color: lightgray;
  color: #777;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-btn.btn-filled:hover:enabled {
  background-color: transparent;
  color: #777;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-btn.btn-border {
  border-color: #bead8e;
}

.main-btn.btn-border:hover {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}

.section-title span.title-tag {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .section-title span.title-tag {
    letter-spacing: 2px;
  }
}

@media (max-width: 399px) {
  .section-title span.title-tag {
    font-size: 12px;
  }
}

.section-title h2 {
  font-size: 80px;
  line-height: 1.125;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 62px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  .section-title h2 {
    font-size: 42px;
  }
}

@media (max-width: 399px) {
  .section-title h2 {
    font-size: 32px;
  }
}

.section-title.white-color span.title-tag {
  color: #bead8e;
}

.section-title.white-color h2 {
  color: #fff;
}

.arrow-style {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.arrow-style .slick-arrow {
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 18px;
  color: #111;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.arrow-style .slick-arrow.next-arrow {
  margin-left: 20px;
}

.arrow-style .slick-arrow:hover, .arrow-style .slick-arrow.next-arrow {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
}
