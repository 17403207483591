.features-loop .feature-box {
  padding: 60px 50px;
  background-color: #fff;
  font-size: 14px;
  background-color: #fff;
  border: 2px solid #f8f8f8;
  position: relative;
  margin-top: 30px;
  height: 400px;
  /*=======  Dark Style  =======*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features-loop .feature-box {
    padding: 50px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-loop .feature-box {
    padding: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .features-loop .feature-box {
    padding: 35px 25px;
  }
}

@media (max-width: 575px) {
  .features-loop .feature-box {
    padding: 50px 30px;
  }
}

.features-loop .feature-box .icon {
  margin-bottom: 30px;
  font-size: 70px;
  color: #bead8e;
  line-height: 1;
}

.features-loop .feature-box:hover .icon i {
  -webkit-animation: hvr-buzz-out 0.75s linear 1;
          animation: hvr-buzz-out 0.75s linear 1;
}

.features-loop .feature-box h3 {
  font-size: 30px;
  margin-bottom: 30px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .features-loop .feature-box h3 {
    font-size: 24px;
  }
}

.features-loop .feature-box .count {
  line-height: 1;
  position: absolute;
  right: 0;
  top: -10px;
  font-size: 120px;
  color: #f8f8f8;
}

.features-loop .feature-box.with-hover-img .hover-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: visible;
  opacity: 1;
}

.features-loop .feature-box.with-hover-img:hover .hover-img {
  opacity: 0;
  visibility: hidden;
}

.features-loop .feature-box.dark-box {
  background-color: #292929;
  border: none;
}

.features-loop .feature-box.dark-box,
.features-loop .feature-box.dark-box h3 a {
  color: #fff;
}

.features-loop .feature-box.dark-box .count {
  color: #222;
}
