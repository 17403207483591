.room-slider {
    position: relative;
    z-index: 1;
  }
  
  .room-slider .rooms-slider-one.row {
    margin-left: -20px;
    margin-right: -20px;
  }
  
  .room-slider .rooms-slider-one.row > .col {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .room-slider .rooms-slider-one .slider-img {
    width: 100%;
    height: 580px;
    background-size: cover;
    background-position: center;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-one .slider-img {
      height: 500px;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-slider-one .slider-img {
      height: 450px;
    }
  }
  
  @media (max-width: 575px) {
    .room-slider .rooms-slider-one .slider-img {
      height: 400px;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-content-wrap {
      margin-bottom: 100px;
    }
  }
  
  .room-slider .rooms-content-wrap .room-content-box {
    background-color: #222;
    color: #d4d4d4;
    font-size: 14px;
    padding: 60px 50px;
    position: relative;
    z-index: 2;
    margin-top: -495px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-content-wrap .room-content-box {
      margin-top: -445px;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-content-wrap .room-content-box {
      margin-top: -100px;
      padding: 50px 30px;
    }
  }
  
  @media (max-width: 575px) {
    .room-slider .rooms-content-wrap .room-content-box {
      margin-top: -50px;
      padding: 50px 30px;
    }
  }
  
  .room-slider .rooms-content-wrap .room-content-box .slider-count {
    position: absolute;
    right: 50px;
    font-size: 14px;
    font-weight: 700;
    top: 90px;
    color: #4c4c4c;
    z-index: 3;
    letter-spacing: 2px;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .slider-count .current {
    color: #bead8e;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .slider-count-big {
    position: absolute;
    bottom: -30px;
    right: 0;
    line-height: 1;
    font-size: 200px;
    z-index: -1;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
    opacity: 0.1;
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-content-wrap .room-content-box .slider-count-big {
      font-size: 150px;
    }
  }
  
  .room-slider .rooms-content-wrap .room-content-box h3 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .room-slider .rooms-content-wrap .room-content-box h3,
  .room-slider .rooms-content-wrap .room-content-box h3 a {
    color: #fff;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .icon {
    line-height: 1;
    color: #bead8e;
    margin-bottom: 40px;
    font-size: 70px;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .room-content-slider {
    padding-bottom: 45px;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .room-content-slider .single-content {
    overflow: hidden;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li {
    display: inline-block;
    cursor: pointer;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li button {
    font-size: 0;
    border: 0;
    background: #434343;
    width: 16px;
    height: 4px;
    margin: 0 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  .room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li.slick-active button {
    width: 26px;
    background-color: #bead8e;
  }
  
  .room-slider .rooms-slider-two .single-rooms-box {
    position: relative;
    margin-bottom: 130px;
    margin-top: 25px;
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .room-img {
    overflow: hidden;
    width: 100%;
    height: 500px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-img {
      height: 420px;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-img {
      height: 350px;
    }
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .room-img .img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  .room-slider .rooms-slider-two .single-rooms-box:hover .img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .icons {
    background-color: #fff;
    padding: 15px;
    position: absolute;
    top: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
            box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    visibility: visible;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .icons li {
    display: inline-block;
    margin: 0px 10px;
    color: #222;
  }
  
  @media (max-width: 575px) {
    .room-slider .rooms-slider-two .single-rooms-box .icons li {
      margin: 0px 5px;
    }
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .room-desc {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: -115px;
    background-color: #fff;
    padding: 40px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
            box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    font-size: 14px;
    visibility: visible;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc {
      left: 20px;
      right: 20px;
      padding: 30px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc {
      left: 20px;
      right: 20px;
      padding: 30px;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc {
      left: 10px;
      right: 10px;
      padding: 25px;
    }
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
    font-size: 36px;
    margin-bottom: 10px;
    letter-spacing: -1px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
      font-size: 32px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
      font-size: 28px;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
      font-size: 26px;
    }
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
    text-align: right;
    font-size: 40px;
    color: #bead8e;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
      font-size: 32px;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
      font-size: 28px;
    }
  }
  
  @media (max-width: 575px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
      text-align: left;
      margin-top: 10px;
    }
  }
  
  .room-slider .rooms-slider-two .single-rooms-box .room-desc .price span {
    font-size: 16px;
  }
  
  .room-slider .rooms-slider-two .slick-current .single-rooms-box .room-desc,
  .room-slider .rooms-slider-two .slick-current .single-rooms-box .icons {
    visibility: visible;
    opacity: 1;
  }
  
  .room-slider .rooms-slider-two .slick-arrow {
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
    font-size: 20px;
    color: #111;
    background: #fff;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: absolute;
    top: 40%;
    z-index: 2;
    left: 22%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .slick-arrow {
      left: 10%;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .slick-arrow {
      left: 5%;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-slider-two .slick-arrow {
      left: 2%;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
    }
  }
  
  .room-slider .rooms-slider-two .slick-arrow.next-arrow {
    margin-left: 20px;
    left: auto;
    right: 22%;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .slick-arrow.next-arrow {
      left: auto;
      right: 10%;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .slick-arrow.next-arrow {
      left: auto;
      right: 5%;
    }
  }
  
  @media (max-width: 767px) {
    .room-slider .rooms-slider-two .slick-arrow.next-arrow {
      left: auto;
      right: 2%;
    }
  }
  
  .room-slider .rooms-slider-two .slick-arrow:hover, .room-slider .rooms-slider-two .slick-arrow.next-arrow {
    background-color: #bead8e;
    color: #fff;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
            box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
  }
  