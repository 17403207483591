
.room-gird-loop .room-box .room-img-wrap {
    overflow: hidden;
    /* width: 100%; */
    height: 240px;
  }
  
  .room-gird-loop .room-box .room-img-wrap .room-img {
    height: 100%;
    /* width: 100%; */
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    background-size: cover;
    background-position: center;
  }
  
  .room-gird-loop .room-box:hover .room-img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .room-gird-loop .room-box .room-desc {
    background-color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 50px 35px 30px;
    position: relative;
  }
  /* 
  .room-grid-loop .room-box .room-desc .icons .icon-amenties{
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
  } */
  
  .room-gird-loop .room-box .room-desc .icons .icon-amenties:Hover{
    color: brown;
    -webkit-transform: scale(2);
            transform: scale(2);
    }
    
  .room-gird-loop .room-box .room-desc .room-title {
    height: 60px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-gird-loop .room-box .room-desc {
      padding: 50px 25px 30px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc {
      padding: 50px 20px 30px;
    }
  }
  
  .room-gird-loop .room-box .room-desc .icons {
    background-color: #fff;
    padding: 15px;
    position: absolute;
    left: 25px;
    right: 25px;
    top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
            box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc .icons {
      left: 12px;
      right: 12px;
      padding: 15px 5px;
    }
  }
  
  .room-gird-loop .room-box .room-desc .icons li {
    display: inline-block;
    margin: 0px 10px;
    color: #222;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-gird-loop .room-box .room-desc .icons li {
      margin: 0 5px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-gird-loop .room-box .room-desc .icons li {
      margin: 0 8px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc .icons li {
      margin: 0 5px;
    }
  }
  
  @media (max-width: 399px) {
    .room-gird-loop .room-box .room-desc .icons li {
      margin: 0 5px;
    }
  }
  
  .room-gird-loop .room-box .room-desc h4 {
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc h4 {
      font-size: 20px;
    }
  }
  
  .room-gird-loop .room-box .room-desc span.price,
  .room-gird-loop .room-box .room-desc a.book-btn {
    font-size: 16px;
    font-weight: 700;
    color: #bead8e;
    margin-top: 25px;
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc span.price,
    .room-gird-loop .room-box .room-desc a.book-btn {
      font-size: 14px;
    }
  }
  .room-gird-loop .room-box .room-desc a.book-btn {
    color: #bdbdbd;
    margin-left: 20px;
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc a.book-btn {
      margin-left: 10px;
    }
  }
  
  .room-gird-loop .room-box .room-desc a.book-btn:hover {
    color: #bead8e;
  }
  